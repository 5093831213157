import React, { useState } from 'react'


const CLIENT_ID = `${process.env.CLIENT_ID}`


export default ({ form, onSubmit, loading }) => {
  const [details, setDetails] = useState({
    username: '',
    password: '',
    client_id: CLIENT_ID,
  })
  const [errors, setErrors] = useState({
    username: '',
    password: '',
    client_id: CLIENT_ID,
  })

  const handleChange = e => {
    setDetails({ ...details, [e.target.name]: e.target.value })
  }

  const handleBlur = e => {
    if (!e.target.value) {
      setErrors({ ...errors, [e.target.name]: 'Required field' })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const { username, password, client_id } = details

    if (!username || !password) {
      setErrors({
        ...errors,
        username: 'Field is required',
        password: 'Field is required',
      })
    } else {
      const data ={
        username,
        password,
        client_id,
        grant_type: 'password'
      }
      onSubmit(data);
    }
  }

  return (
    <form className="form_auth" onSubmit={handleSubmit}>
        <div className="form-group">
            {/* <label>Логин</label> */}
            <input 
              type="text"
              className="form-control"
              onChange={handleChange}
              onBlur={handleBlur}
              name="username"
              style={errors.username ? {borderColor: 'red'} : null}
              placeholder="Введите номер телефона"/>
        </div>
        {/* <div className="form-group">
            <label>Пароль</label>
            <input 
              type="password"
              className="form-control"
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              placeholder="Введите пароль"/>
              {errors.password && (
                <span style={{ color: 'red' }}>{errors.password}</span>
              )}
        </div> */}
        <button
          type="submit"
          disabled={loading}
          className="btn btn-default sub_btn">
            Войти
        </button>
    </form>
  )
}

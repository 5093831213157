export const redirectToApp = () => {

    let deparam = (function(d,x,params,p,i,j) {
        return function (qs) {
            params = {};
            qs = qs.substring(qs.indexOf('?')+1).replace(x,' ').split('&');
            for (i = qs.length; i > 0;) {
                p = qs[--i];
                j = p.indexOf('=');
                if(j === -1) params[d(p)] = undefined;
                else params[d(p.substring(0,j))] = d(p.substring(j+1));
            }

            return params;
        };
    })(decodeURIComponent, /\+/g);

    const userAgent = typeof window === "undefined" ||
        window.navigator === "undefined" ? "" : navigator.userAgent;
    let isAndroid = userAgent.match('Android');
    let isiOS = userAgent.match('iPhone|iPad|iPod');

    console.log(`>> userAgent: ${userAgent}, isAndroid: ${isAndroid}, isiOS: ${isiOS}`);

    if (isiOS || isAndroid) {
        //Check and set up an iOS failsafe first. 
        if (isiOS) {
            setTimeout(() => {
                window.location = process.env.PLAY_STORE_URL; //it's not actually play store, it's app store
            }, 1000)
        }
        //if no app is present - we then try to open Play Store via a deeplink. We grab the ID from the regular link.
        var playURL = process.env.GOOGLE_PLAY_URL;
        if(isAndroid) {
            setTimeout(() => {
                try{
                    var packageName = playURL.split('=')[1]
                }catch(e){
                    packageName = 'uz.uzinfocom.myid'
                }
                window.location = 'market://details?id=' + packageName;
            }, 700)
            
            //Set up a normal google play failsafe in case deeplinks will not work.
            setTimeout(() => {
                window.location = playURL;
            }, 1000);
        }

        //If there are typical auth parameters, we try to pass auth to the app via an /authorize deeplink.
        if(window.location.search.indexOf('client_id') > -1 && process.env.MOBILE_APP_AUTHORIZATION) {
            //capture existing parameters and add the ones required for the app
            let params = deparam(window.location.search);
            let location =
                process.env.MOBILE_APP_AUTHORIZATION +
                window.location.search +
                '&method=' + params.auth_method +
                '&scope=' + params.scopes;
            console.log(location);
            window.location = location;
        }else{
            //Else we just open the app.
            window.location = process.env.MOBILE_APP_ID;
        }
    }
}
